import { Component } from '@angular/core';
import { Utils } from "./utils/utils";
import { PrimeNGConfig } from 'primeng/api';
import { Aura } from "primeng/themes/aura";
import { LoaderService } from './providers/loader/loader.service';
// import { Lara } from "primeng/themes/lara";
// import { Nora } from "primeng/themes/nora";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Google-Quota-Optimizer';
 constructor(private config: PrimeNGConfig, public loaderService: LoaderService ) {
  this.config.theme.set({ preset: Aura });
 }
}
