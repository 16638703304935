import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _showLoader: boolean = false;
  public get showLoader(): boolean {
    return this._showLoader;
  }
  public set showLoader(value: boolean) {
    this._showLoader = value;
  }
  constructor() { }
}
