import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/config/appconfig';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _userDetails: any = {
    userEmail: '',
    userName: '',
    userPhoto: ''
  };
  public get userDetails(): any {
    return this._userDetails;
  }
  public set userDetails(value: any) {
    this._userDetails = value;
  }
  public resetUser() {
    this.userDetails = {
      userEmail: '',
      userName: '',
      userPhoto: ''
    };
    this.clearUserState();
  }

  private _storedUserState: any;
  public get storedUserState(): any {
    const data = localStorage.getItem(AppConfig.LOCALSTORAGE_KEY);
    this._storedUserState = null;
    if (data) {
      try {
        this._storedUserState =  JSON.parse(data);
      } catch (e) {
        this._storedUserState =  data;
      }
    }
    return this._storedUserState;
  }

  public set storedUserState(value: any) {
    this._storedUserState = value;
    localStorage.setItem(AppConfig.LOCALSTORAGE_KEY, typeof value === "string" ? value : JSON.stringify(value));
  }

  public clearUserState() {
    localStorage.clear();
  }

  constructor() { }
}
