import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private _token!: string;
  public get token(): string {
    return this._token;
  }
  public set token(value: string) {
    this._token = value;
  }
  
  constructor() { }
}
