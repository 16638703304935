export const AppConfig  = {
    analytics: false,
    header: true,
    headerConfig: {
        logo: 'assets/images/logo.png',
        title: 'Google Quota Optimizer',
        subTitle: '',
        profile: true
    },
    sideBar: true,
    breadcrumb: false,
    MenuItems: [{
        icon: 'assets/images/home.png',
        name: 'Home',
        tooltip: 'Dashboard',
        link: 'dashboard'
    }, {
        icon: 'assets/images/gmail.png',
        name: 'GMail',
        tooltip: 'GMail',
        link: 'gmail'
    }, {
        icon: 'assets/images/gdrive.png',
        name: 'GDrive',
        tooltip: 'GDrive',
        link: 'gdrive'
    }],
    footer: true,
    footerConfig: {
        copyright: '@Holcim 2024',
        links: [{
            name: 'Privacy Policy',
            link: ''
        },{
            name: 'Terms',
            link: ''
        },{
            name: 'Disclaimer',
            link: ''
        }]
    },
    ENDPOINTS: {
        DASHBOARD_TOTAL_STORAGE: 'drive/totalStorage',
        GDRIVE_FILTER: 'drive/searchByFilter',
        GDRIVE_SUGG_FILTER: {
            LARGE: 'drive/listOfLargerFiles',
            DUPLICATE: 'drive/listOfDuplicateFiles',
            TRASH: 'drive/listOftrashedFiles'
        },
        GDRIVE_DELETE_PERMANENTLY: 'drive/deleteFiles',
        GDRIVE_ARCHIEVE: 'drive/deleteFiles',
        GMAIL_FILTER: 'gmail/searchByFilter',
        GMAIL_LABEL: 'gmail/getLabel',
        GMAIL_SUGG_FILTER: {
            ATTACHMENT: 'gmail/emailWithAttachment',
            TRASH: 'gmail/listOftrashedEmail'
        },
        GMAIL_DELETE_PERMANENTLY: 'gmail/deleteEmails',
        GMAIL_ARCHIEVE: 'gmail/deleteEmails',
    },
    LOCALSTORAGE_KEY: 'UserSession'    
}

// GMAIL_SYSTEM_LABEL_VISIBILITY: {
//     Label_1: true,
//     IMPORTANT: true,
//     STARRED: true
// }
