
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../providers/user/user.service';
import { AppService } from '../providers/application/app.service';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService, private appService: AppService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // return this.socialAuthService.authState.pipe(
    //   map((socialUser: SocialUser) => !!socialUser),
    //   tap((isLoggedIn: boolean) => {
    //     if (!isLoggedIn) {
    //       this.router.navigate(['login']);
    //     }
    //   })
    // );
    const signInUserSession: any = this.userService.storedUserState;
    if (signInUserSession) {
      this.updateUserDetailsFromStorage(signInUserSession);

    }
    let isUserAuthenticated: boolean = false;
    if(this.userService.userDetails.userEmail != '') {
      isUserAuthenticated = true
    } else {
      this.router.navigate(['login']);
    }
    
    return isUserAuthenticated;
  }

  updateUserDetailsFromStorage(signInUserSession: any) {
    const signInResponse: any = jwtDecode(signInUserSession);
    this.appService.token = signInUserSession;
    this.userService.userDetails.userEmail = signInResponse.email;
    this.userService.userDetails.userName = signInResponse.name;
    this.userService.userDetails.userPhoto = signInResponse.picture;
  }
};
